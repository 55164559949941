import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import CardIcons from '../components/cardIcons.js'
import CarouselQuote from '../components/carouselQuote'

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false /* eslint-disable import/first */

const About = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}       
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.bragroll && (
          <section className="bg-gray-light">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.bragroll.title}
                </h2>
                {post.frontmatter.bragroll.card && (
                  <MDBRow>
                    <>
                      {post.frontmatter.bragroll.card.map(cards => {
                        return (
                          <CardIcons
                            collg="4"
                            colmd="6"
                            title={cards.title}
                            subtitle={cards.subtitle}
                            image={cards.image.childImageSharp.gatsbyImageData}
                            alt={cards.alttext}
                          />
                        )
                      })}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}   

      {post.frontmatter.quote && (
        <CarouselQuote data={post.frontmatter.quote} />
      )}
      </main>
    </Layout>
  )
}
export default About 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        strapline
        ceo
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        quote {
          strapline
          author
        }
      }
      html
    }
  }
`
